<template>
  <div>
    <Loader :loading="loading" />
    <div class="d-flex justify-space-between">
      <div style="width: 150px;">
        <v-select
          v-model="perPage"
          label="Total visible"
          :items="listPerPage"
          @change="resetCurrentPage"
          dense
        />
      </div>
      <div>
        <v-btn
          @click="getUsers"
          color="warning"
          small
          text
        >
          <v-icon left>mdi-refresh</v-icon>
          Refrescar
        </v-btn>
        <v-btn
          @click="createUser"
          color="primary"
          small
          text
        >
          <v-icon left>mdi-plus</v-icon>
          Usuario
        </v-btn>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="users"
      class="elevation-0"
      disable-pagination
      hide-default-footer
      fixed-header
      :height="heightUsers"
    >
      <template v-slot:item.actions="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              link
              @click="editUser(item)"
            >
              <v-icon left>mdi-pencil</v-icon>
              <small>Editar</small>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ getDateFormat(item.created_at) }}
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        v-model="currentPage"
        :disabled="loading"
        :length="lastPage"
        :total-visible="8"
      />
    </div>
    <CreateUser
      ref="createUser"
      @onSendUser="onSendUser"
    />
  </div>
</template>

<script>
import UserServices from '@/store/services/goliath/UserServices';
import ListMixin from '@/mixins/commons/ListMixin';
import { getDateFormat } from '@/constants/helpers';
import { mapGetters } from 'vuex';
import CreateUser from './CreateUser';

export default {
  mixins: [ListMixin],
  components: {
    CreateUser,
  },
  data() {
    return {
      loading: false,
      users: [],

      heightUsers: window.innerHeight - 200,
    };
  },
  methods: {
    getDateFormat(date) {
      return getDateFormat(date);
    },
    async getUsers() {
      try {
        const params = {
          page: this.currentPage,
          order_by: 'id',
          order_direction: 'DESC',
          per_page: typeof this.perPage === 'string' ? this.total : this.perPage,
          include: 'archons',
          search_archon: this.user.archon.id,
        };
        this.loading = true;
        const response = await UserServices.index(params);
        console.log(response);
        this.users = response.data.data
        this.currentPage = response.data.current_page
        this.lastPage = response.data.last_page
        this.total = response.data.total
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    resetCurrentPage() {
      if (this.currentPage === 1) this.getUsers();
      else this.currentPage = 1;
    },
    editUser(item) {
      // console.log(item);
      this.$refs['createUser'].dialog = true;
      this.$refs['createUser'].userId = item.id;
      this.$refs['createUser'].body = {
        name: item.name,
        email: item.email,
        password: '',
      };
    },
    createUser() {
      this.$refs['createUser'].dialog = true;
    },
    onSendUser(data) {
      if (data.type === 'store') this.users.unshift(data.user);
      else {
        const index = this.users.findIndex(user => user.id === data.userId);
        this.users[index].name = data.user.name;
        this.users[index].password = data.user.password;
      }
    },
  },
  created() {
    this.getUsers()
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    headers() {
      return [
        {
          text: '',
          sortable: false,
          value: 'actions',
        },
        {
          text: 'Nombre',
          sortable: false,
          value: 'name',
        },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Creado', value: 'created_at', sortable: false },
      ];
    },
  }
}
</script>

<style>
  .flex {
    flex-grow: 0;
  }
</style>