<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <LoaderThreeBody
        absolute
        :loading="loading"
      />
      <v-card-title>
        <v-icon left>mdi-account</v-icon>
        {{ userId === -1 ? 'Nuevo Usuario' : 'Editar Usuario' }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="body.name"
            label="Nombre"
            :rules="[isRequired]"
            :error-messages="errorName"
          />
          <v-text-field
            v-model="body.email"
            label="Email"
            :rules="[isRequired, isEmail]"
            :error-messages="errorEmail"
            :disabled="userId !== -1"
          />
          <v-text-field
            v-model="body.password"
            label="Contraseña"
            :rules="[isRequired]"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="cancel"
          small
          color="grey lighten-1"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="userId === -1"
          @click="storeUser"
          small
          color="success"
        >
          Agregar
        </v-btn>
        <v-btn
          v-else
          @click="updateUser"
          small
          color="warning"
        >
          Editar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// services
import UserServices from '@/store/services/goliath/UserServices'
// helpers
import { isEmail } from '@/constants/rules';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      dialog: false,
      loading: false,

      body: {
        name: '',
        email: '',
        password: '',
      },

      // rules
      isRequired: value => !!value || 'Campo requerido',
      isEmail: value => isEmail(value) || 'Email invalido',

      errorName: '',
      errorEmail: '',

      showPassword: false,

      userId: -1,
    };
  },
  methods: {
    cancel() {
      this.dialog = false;
      this.resetData();
    },
    resetData() {
      this.body = {
        name: '',
        email: '',
        password: '',
      };
      this.$refs.form.resetValidation();

      this.userId = -1;
    },
    async updateUser() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          await UserServices.update(this.body, this.userId);
          this.loading = false;
          this.dialog = false;
          this.$emit('onSendUser', {
            user: Object.assign({}, this.body),
            userId: this.userId,
            type: 'update',
          });
          this.resetData();
        } catch (error) {
          this.loading = false;
          if (error.response.status === 422) {
            this.errorName = error.response.data.errors?.name?.[0] ?? '';
            this.errorEmail = error.response.data.errors?.email?.[0] ?? '';
          }
        }
      }
    },
    async storeUser() {
      if (this.$refs.form.validate()) {
        try {
          this.errorName = '';
          this.errorEmail = '';
          this.loading = true;
          this.body['archons_id'] = [this.user.archon.id];
          const response = await UserServices.store(this.body);
          this.$emit('onSendUser', {
            user: response.data,
            type: 'store',
          });
          this.loading = false;
          this.dialog = false;
          this.resetData();
        } catch (error) {
          this.loading = false;
          if (error.response.status === 422) {
            this.errorName = error.response.data.errors?.name?.[0] ?? '';
            this.errorEmail = error.response.data.errors?.email?.[0] ?? '';
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
};
</script>